<template>
  <div class="personal-data">
    <div class="personal-data__name">
      <p>Name:</p>
      <p v-if="usersData?.profile?.name">{{ usersData.profile.name }}</p>
      <p v-else>—</p>
    </div>
    <div class="personal-data__phone">
      <p>Phone:</p>
      <p v-if="usersData?.profile?.phone">{{ usersData.profile.phone }}</p>
      <p v-else>—</p>
    </div>
    <div class="personal-data__email">
      <p>Email:</p>
      <p v-if="usersData?.email">{{ usersData.email }}</p>
      <p v-else>—</p>
    </div>
    <div class="personal-data__address">
      <p>Address:</p>
      <div v-if="usersData?.profile?.state">
        <p v-if="usersData?.profile?.street">{{ usersData.profile.street }}</p>
        <p v-if="usersData?.profile?.street2">
          {{ usersData.profile.street2 }}
        </p>
        <p v-if="usersData?.profile?.city">{{ usersData.profile.city }}</p>
        <p v-if="usersData?.profile?.state">{{ usersData.profile.state }}</p>
        <p v-if="usersData?.profile?.zipcode">
          {{ usersData.profile.zipcode }}
        </p>
      </div>
      <div v-else>
        <p>—</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/AuthStore";
import { computed } from "vue";

const userStore = useAuthStore();
export default {
  name: "ProfilePersonalInfoTab",
  setup() {
    const usersData = computed(() => {
      if (userStore.currentUser) return userStore?.currentUser;

      return {};
    });

    return {
      usersData,
    };
  },
};
</script>

<style lang="scss" scoped>
.personal-data {
  & > div {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
    color: var(--base-text-color);
    p {
      word-break: break-all;
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }

    &:last-child {
      align-items: baseline;
    }
    & > p:first-child {
      display: inline-block;
      margin-right: 64px;
      font: normal normal bold 20px Montserrat;
      width: 100%;
      max-width: 90px;
      word-break: normal;
      @media screen and (max-width: 500px) {
        font-size: 16px;
        margin-right: 1rem;
      }
    }
    & > p:nth-child(2) {
      font: normal normal normal 20px/35px Montserrat;
    }
  }

  &__address {
    p {
      font: normal normal 400 20px/35px Montserrat;
    }
    // & p:nth-child(2) {
    //   max-width: 160px;
    // }
  }
}
</style>
