<template>
  <div>
    <div class="orders">
      <div class="orders__vin">
        <p class="orders__subtitle">CONFIRMATION #</p>
        <p
          class="orders__vin-data"
          v-for="(item, index) in orders"
          :key="index"
        >
          {{ item.confirmation || "—" }}
          <!-- {{ item.vin }} -->
        </p>
      </div>
      <div class="orders__statuses">
        <p class="orders__subtitle">Status</p>
        <p
          class="orders__status-data"
          v-for="(item, index) in orders"
          :key="index"
        >
          {{ item.order_status }}
        </p>
      </div>
    </div>
    <div v-if="orders.length === 0" class="noScheduledOrders">
      No scheduled installs yet
    </div>
  </div>
</template>

<script>
import { scheduledData } from "@/common/scheduledInstallsData";
import { computed, reactive } from "vue";
import { useAuthStore } from "@/store/AuthStore";

const userStore = useAuthStore();
export default {
  name: "ProfileScheduledInstalls",
  data() {
    return {
      scheduledData,
    };
  },
  setup() {
    const usersOrdersData = reactive(userStore.currentUser.orders);
    const orders = computed(() => {
      const data = usersOrdersData.map((order) => {
        return {
          ...order,
          order_status:
            order.order_status === "not_filled_by_user"
              ? "Request Received"
              : order.order_status,
        };
      });
      return data;
    });
    return {
      usersOrdersData,
      orders,
    };
  },
};
</script>

<style lang="scss" scoped>
.orders {
  display: flex;
  margin-bottom: 50px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  &__subtitle {
    font: normal normal bold 25px/68px Montserrat;
    color: var(--buzz-charging-dark-blue);

    &:first-child {
      margin-right: 216px;
      margin-bottom: 50px;
      @media (max-width: 1000px) {
        margin-right: 100px;
      }
      @media (max-width: 600px) {
        margin-right: 0;
      }
    }
  }

  &__vin-data,
  &__status-data {
    color: var(--base-text-color);
    font: normal normal medium 20px/35px Montserrat;
    padding-bottom: 16px;
  }
}
.noScheduledOrders {
  color: var(--base-text-color);
  font: normal normal medium 20px/35px Montserrat;
  padding-bottom: 16px;
}
</style>
