<template>
  <!-- <img src="/images/SignIn.png" alt="" class="new-schedule__image" /> -->
  <div class="newSchedule" @click="moveToSchedule">
    <h2 class="newSchedule-title">Schedule a New Install</h2>
    <span class="newSchedule-icon"></span>
  </div>
</template>

<script>
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ProfileNewScheduleInstall",
  computed: {
    persistStep() {
      try {
        const step = JSON.parse(localStorage.getItem("schedule")).scheduleStep;
        if (
          JSON.parse(localStorage.getItem("authenticated")) &&
          (step === 1 || step === 20)
        ) {
          return 2;
        } else {
          return step;
        }
      } catch (e) {
        return 1;
      }
    },
  },
  methods: {
    moveToSchedule() {
      stepsStore.resetSteps();
      stepsStore.clearInfo();
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.newSchedule {
  margin-bottom: 50px;
  padding: 55px 100px 50px 100px;
  color: #fff;
  text-align: center;
  min-height: 368px;
  max-width: 400px;
  // max-height: 368px;
  // height: 100%;
  width: 100%;
  background: var(--buzz-charging-dark-blue);
  border-radius: 25px;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    margin-bottom: 100px;
  }
  @media (max-width: 500px) {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-title {
    font: normal normal bold 30px/68px Montserrat;
    line-height: 40px;
    margin-bottom: 24px;
  }

  &-icon {
    background-image: url("/images/calendar.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 180px;
    height: 180px;
    color: #fff;
    display: inline-block;
    @media (max-width: 550px) {
      max-width: 100px;
      max-height: 100px;
    }
  }
}
</style>
