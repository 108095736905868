export const scheduledData = [
  {
    vin: "4Y1SL65848Z411439",
    status: "Request Received",
  },
  {
    vin: "5Y1SL65848Z411439",
    status: "Installation Confirmed",
  },
  {
    vin: "6Y1SL65848Z411439",
    status: "Installation Completed",
  },
];
