<template>
  <DefaultLayout :fullWidth="true">
    <section class="profile">
      <h2 class="profile__title page-title">
        {{ userTitle.toUpperCase() }}
      </h2>
      <div class="profile__actions">
        <button
          class="profile__actions-btn"
          @click="selectedTab = 1"
          :class="{ active: selectedTab === 1 }"
        >
          Personal Information
        </button>
        <button
          class="profile__actions-btn"
          @click="selectedTab = 2"
          :class="{ active: selectedTab === 2 }"
        >
          Scheduled Installs
        </button>
        <button class="new-schedule-btn" @click="selectedTab = 3">
          <div class="new-schedule-btn__contents">Schedule a New Install</div>
        </button>
      </div>
      <component :is="currentTabComponent"></component>
      <button
        v-if="persistStep !== 2"
        class="new-schedule-btn"
        @click="moveToSchedule"
      >
        <div class="new-schedule-btn__contents">Resume Installation</div>
      </button>
      <div class="additionalInfoBlock">
        <p>
          Any questions or changes to your account, please get in touch with our
          office at <a href="tel:+18605061500">(860) 506-1500</a> or
          <a href="mailto:support@buzzcharging.com">support@buzzcharging.com</a>
        </p>
      </div>
    </section>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/views/layouts/default";
import ProfileNewScheduleInstall from "@/components/profile/ProfileNewScheduleInstall";
import ProfilePersonalInfoTab from "@/components/profile/ProfilePersonalInfoTab";
import ProfileScheduledInstalls from "@/components/profile/ProfileScheduledInstalls";
import { useAuthStore } from "@/store/AuthStore";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();

const authStore = useAuthStore();
export default {
  name: "ProfilePage",
  components: {
    ProfileScheduledInstalls,
    ProfilePersonalInfoTab,
    ProfileNewScheduleInstall,
    DefaultLayout,
  },
  data() {
    return {
      selectedTab: 1,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    currentTabComponent() {
      if (this.selectedTab === 1) {
        return "ProfilePersonalInfoTab";
      } else if (this.selectedTab === 2) {
        return "ProfileScheduledInstalls";
      } else {
        return "ProfileNewScheduleInstall";
      }
    },
    userTitle() {
      if (authStore?.currentUser?.profile?.name)
        return authStore.currentUser.profile.name;
      if (authStore?.currentUser?.email) return authStore.currentUser.email;

      return "Guest";
    },
    persistStep() {
      try {
        const step = JSON.parse(localStorage.getItem("schedule")).scheduleStep;
        if (
          JSON.parse(localStorage.getItem("authenticated")) &&
          (step === 1 || step === 20)
        ) {
          return 2;
        } else {
          return step;
        }
      } catch (e) {
        return 1;
      }
    },
  },
  methods: {
    fetchData() {
      authStore.getUserData().catch((v) => {
        console.log("error: ", v);
        this.$router.push({
          path: "/",
        });
      });
    },
    moveToSchedule() {
      stepsStore.setStep(this.persistStep);
      this.$router.push({
        path: "/schedule",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  // margin: 0 -40px;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
  }
  &__title {
    color: var(--buzz-charging-dark-blue);
    margin-bottom: 56px;
    font-size: 45px;
    word-break: break-all;
    @media screen and (max-width: 600px) {
      font-size: 25px;
      line-height: 1.25;
    }
  }

  &__actions {
    margin-bottom: 100px;
    display: flex;
    @media screen and (max-width: 1260px) {
      flex-wrap: wrap;
      & > :nth-child(3) {
        margin-top: 10px;
      }
    }
    @media screen and (max-width: 600px) {
      justify-content: center;
    }

    &-btn {
      font: normal normal bold 25px Montserrat;
      color: var(--base-text-color);
      background-color: #fff;
      border: none;
      cursor: pointer;
      padding: 0 0 0 0;
      margin-right: 136px;
      border-bottom: 3px solid transparent;
      @media screen and (max-width: 1260px) {
        margin-right: 60px;
        padding-bottom: 5px;
      }
      @media screen and (max-width: 600px) {
        margin: 0 auto 15px;
      }
      @media screen and (max-width: 500px) {
        font-size: 20px;
        padding-bottom: 5px;
      }
    }
    button {
      @media screen and (max-width: 1260px) {
        margin-bottom: 10px;
      }
    }
  }
}
.active {
  border-bottom: 3px solid var(--buzz-charging-yellow) !important;
}
.new-schedule-btn {
  max-width: 360px;
  background: var(--buzz-charging-dark-blue);
  color: #fff;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 7px;

  &__contents {
    font: normal normal bold 25px Montserrat;
    padding: 0;
    display: inline-block;
    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
}
.additionalInfoBlock {
  margin-top: 5rem;
  color: var(--base-text-color);
  font: normal normal 400 20px/40px Montserrat;
  a {
    color: inherit;
    text-decoration: none;
  }
}
</style>
